@font-face {
  font-family: 'Noto Sans CJK';
  font-weight: 400;
  src: url("../font/NotoSansCJKkr-Regular.otf");
}

body {
  font-family: 'Noto Sans CJK' !important;
  background-color: #f8f9fa !important;
}

.App {
  width: 100%;
  height: 100%;
}
